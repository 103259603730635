import './App.css';

function App() {
  return (
<div className="App">
      <header className="App-header">
        <p>
          Start Shopping with Ohanas Store Today
        </p>
        <a
          className="App-link"
          href="https://www.ohanasshop.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Ohana's Store 
        </a>
      </header>
    </div>
  );
}

export default App;
